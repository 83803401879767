import React, { useState, useEffect, MouseEvent } from 'react';
import { buildClassName } from 'utils/build-class-name';
import { useModalContext, useSavedListingContext, useThemeContext, useUserContext } from 'contexts';
import HollowHeartIcon from 'components/icon/hollow-heart-icon';
import styles from './style.module.scss';
import { listingCardIds } from 'constants/test-constants';
import testHookData from 'hooks/test-hook-data';
import { ThemeNames } from 'types/themes';

type FavouriteToggle = {
  listingId: number;
  isInCarousel?: boolean;
  isSmallCard?: boolean;
  isModal?: boolean;
};

type FavouriteToggleBaseProps = {
  toggleFavouriteState: () => void;
  isFavourite: boolean;
  isInCarousel?: boolean;
  isSmallCard?: boolean;
};

export const FavouriteToggleBase = ({ isInCarousel, isSmallCard, isFavourite, toggleFavouriteState }: FavouriteToggleBaseProps) => {
  const containerClassName = buildClassName(isInCarousel && styles.component, isSmallCard && styles['small-card'], styles['favourite-wrapper'], isInCarousel && styles['address-page-icon']);
  const testIdData = isFavourite ? testHookData('favourite') : testHookData('notFavourite');
  const handleOnCLick = (event: MouseEvent) => {
    event.stopPropagation();
    toggleFavouriteState?.();
  };
  const { themeName } = useThemeContext();
  const isExpTheme = themeName && themeName !== ThemeNames.ZOOCASA;

  return (
    <div className={containerClassName} onClick={handleOnCLick} data-testid={listingCardIds.favouriteToggle} {...testIdData}>
      <HollowHeartIcon className={buildClassName(styles.favourite, isFavourite && styles.active, isExpTheme && styles.exp)} onClick={handleOnCLick} />
    </div>
  );
};

export default function FavouriteToggle({ listingId, isInCarousel, isSmallCard, isModal = false }: FavouriteToggle) {
  const [isFavourite, setIsFavourite] = useState(false);
  const { openModal } = useModalContext();
  const { isAuthenticated } = useUserContext();
  const { savedListingIDs, addSavedListing, removeSavedListing } = useSavedListingContext();

  useEffect(() => {
    setIsFavourite(savedListingIDs?.includes(listingId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedListingIDs]);

  const toggleFavouriteState = () => {
    if (isAuthenticated) {
      if (isFavourite) {
        removeSavedListing(listingId);
      } else {
        addSavedListing(listingId, isModal);
      }
    } else {
      openModal('login-registration');
    }
  };

  return (
    <>
      <FavouriteToggleBase 
        isFavourite={isFavourite}
        isInCarousel={isInCarousel}
        isSmallCard={isSmallCard}
        toggleFavouriteState={toggleFavouriteState}
      />
    </>
  );
}
