import { pluralizeWithCount } from '@zoocasa/node-kit/strings/pluralize';
import dayjs from 'dayjs';

export default function dateFromNow(date: Date) {
  const diffInSeconds = dayjs(new Date()).diff(dayjs(date), 'second');
  const second = 1;
  const minute = 60 * second;
  const hour = 60 * minute;
  const day = 24 * hour;
  const month = 30 * day;
  const year = 365 * day;
  const intervals = { second, minute, hour, day, month, year };

  const labels = Object.keys(intervals) as ('second' | 'minute' | 'hour' | 'day' | 'month' | 'year')[];
  for (let i = 0; i < labels.length; i++) {
    const nextInterval = intervals[labels[i + 1]];
    if (nextInterval && diffInSeconds < nextInterval || !nextInterval) {
      const label = labels[i];
      const interval = intervals[label];
      const time = Math.round(diffInSeconds / interval);
      return pluralizeWithCount(time, label) + ' ago';
    }
  }
}
