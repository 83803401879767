import React from 'react';
import ImageLoader from 'components/image-loader';
import { ProvinceOrStateCode, isUsStateCode } from 'utils/province_or_state';
import styles from './style.module.scss';

import type { ListingCardData } from 'components/listing-card';

interface Props {
  listing: ListingCardData;
  isSmallCard?: boolean;
  isOnMapSidePanel?: boolean;
}

export default function ProviderLogo({ listing, isSmallCard = false, isOnMapSidePanel = false }: Props) {
  const { province, providerId } = listing;
  const isAmericanListing = isUsStateCode(province?.toLowerCase() as ProvinceOrStateCode);
  const wrapperClassname = isSmallCard ? styles['wrapper-small'] : !isOnMapSidePanel ? styles.wrapper : '';

  if (!isAmericanListing) {
    return null;
  }

  return (
    <div className={wrapperClassname}>
      <div className={styles.component}>
        <ImageLoader className={styles['provider-logo']} src={`https://zoocasa.s3.amazonaws.com/provider-logos/PROV_LOGO_${providerId}.png`} alt="MLS logo" />
      </div>
    </div>
  );
}
