import React, { MouseEvent } from 'react';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
  isExpTheme?: boolean;
}

const BedIcon = ({ className, onClick, isExpTheme }: Props) => {
  const { themeName } = useThemeContext();
  const isExpThemeFromContext = themeName && themeName !== ThemeNames.ZOOCASA;

  return (
    <>
      { !(isExpTheme || isExpThemeFromContext) ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" className={className} onClick={onClick}>
          <path fill="#191B1E" fillRule="evenodd" d="M16.875 9.918H5.625v-3.5H15.75c.621 0 1.125.523 1.125 1.164zm-15.75 0H4.5v-3.5H1.125zM2.25 4.082H4.5c.621 0 1.125.523 1.125 1.168h-4.5c0-.645.504-1.168 1.125-1.168zm13.5 1.168h-9c0-1.285-1.008-2.332-2.25-2.332H2.25c-.41 0-.793.113-1.125.312V.582A.575.575 0 00.562 0 .572.572 0 000 .582v12.836c0 .32.25.582.563.582a.575.575 0 00.562-.582v-2.336h15.75v2.336c0 .32.25.582.563.582a.575.575 0 00.562-.582V7.582c0-1.285-1.008-2.332-2.25-2.332zm0 0" />
        </svg> 
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="1 -2.5 10 13" className={className} onClick={onClick}>
          <path d="M13.6666 5.00004C13.6666 4.26671 13.0666 3.66671 12.3333 3.66671V1.66671C12.3333 0.933374 11.7333 0.333374 10.9999 0.333374H2.99992C2.26659 0.333374 1.66659 0.933374 1.66659 1.66671V3.66671C0.933252 3.66671 0.333252 4.26671 0.333252 5.00004V8.33337H1.21992L1.66659 9.66671H2.33325L2.77992 8.33337H11.2266L11.6666 9.66671H12.3333L12.7799 8.33337H13.6666V5.00004ZM10.9999 3.66671H7.66659V1.66671H10.9999V3.66671ZM2.99992 1.66671H6.33325V3.66671H2.99992V1.66671ZM1.66659 5.00004H12.3333V7.00004H1.66659V5.00004Z" fill="#0C0F24"/>
        </svg>
      )}
    </>
  );
};

export default BedIcon;
