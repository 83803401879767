import React from 'react';
import styles from './style.module.scss';
import { listingCardIds } from 'constants/test-constants';
import { buildClassName } from 'utils/build-class-name';

export type ProviderLabelsProps = {
  mlsNum: string | null;
  brokerage: string | null;
  hasLogo?: boolean;
  isBlurred?: boolean;
};

export default function ProviderLabels({ mlsNum, brokerage, hasLogo = false, isBlurred = false }: ProviderLabelsProps) {
  const labelClass = buildClassName(styles.label, hasLogo ? styles['has-logo'] : styles['no-logo']);

  if (!mlsNum) {
    return null;
  }

  return (
    <p className={labelClass}>MLS®&nbsp;
      <span className={isBlurred ? styles.blur : ''} data-testid={listingCardIds.listingMlsNum}>{mlsNum} {brokerage && !isBlurred ? `• ${brokerage}` : ''}</span>
    </p>
  );
}
