import React from 'react';
import SqftIcon from 'components/icon/sqft-icon';
import BathIcon from 'components/icon/bath-icon';
import BedIcon from 'components/icon/bed-icon';
import SqftText from 'components/sqft-text';
import styles from './style.module.scss';
import { listingCardIds } from 'constants/test-constants';
import { buildClassName } from 'utils/build-class-name';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

import type { SimilarListingCardData } from 'components/dynamic-page/listing-page/similar-listings-sections/similar_listing_card_data';

export interface ListingBedBathSqftLabelsData {
  bedrooms: number;
  bathrooms: number | null;
  avgSqft: number | null;
  sqftLabel: string;
  isCrea: boolean;
  isItsoVow: boolean;
  isPillar9Vow: boolean;
  isRebgvVow: boolean;
  providerId: number | null;
  agreementType: string | undefined;
}
interface Props {
  listing: ListingBedBathSqftLabelsData;
  listingToCompare?: ListingBedBathSqftLabelsData | SimilarListingCardData;
  isPrivate: boolean;
  requiresTerms: boolean;
  isWidget?: boolean;
}

export default function ListingBedBathSqftLabels({ listing, listingToCompare, isPrivate, requiresTerms }: Props) {
  const { themeName } = useThemeContext();
  const isExpTheme = themeName && themeName !== ThemeNames.ZOOCASA;

  let differenceInBedrooms = 0;
  let differenceInBathrooms = 0;
  let differenceInSqft = 0;

  if (listingToCompare) {
    differenceInBedrooms = listing.bedrooms - listingToCompare.bedrooms;
    differenceInBathrooms = Number(listing.bathrooms) - Number(listingToCompare.bathrooms);
    differenceInSqft = Number(listing.avgSqft) - Number(listingToCompare.avgSqft);
  }

  const getPlusMinusPrefix = (differenceInStat: number) => differenceInStat > 0 ? '+' : '';

  const getArrowClassName = (differenceInStat: number) => {
    if (differenceInStat > 0) {
      return 'arrow-up';
    } else if (differenceInStat < 0) {
      return 'arrow-down';
    }
    return '';
  };

  const getDifferenceLabel = (differenceInStat: number) => {
    if (differenceInStat !== 0) {
      return `(${getPlusMinusPrefix(differenceInStat)} ${differenceInStat})`;
    }
  };

  const renderModalDialog = () => {
    return (
      <div className={buildClassName(styles.row, isExpTheme && styles.exp)}>
        <div>
          <p className={styles['listing-detail']} data-testid={listingCardIds.listingBeds}>
            <BedIcon className={styles.icon} />
            {(isPrivate || requiresTerms) ? '-' : (isExpTheme ? `${listing.bedrooms} Bd` : `${listing.bedrooms} Bed`)}
              
            {!(isPrivate || requiresTerms) && listingToCompare &&
                <>
                  {getDifferenceLabel(differenceInBedrooms)}
                  <span className={styles[getArrowClassName(differenceInBedrooms)]} />
                </>
            }
          </p>
          <p className={styles['listing-detail']} data-testid={listingCardIds.listingBathrooms}>
            <BathIcon className={styles.icon} />
            {(isPrivate || requiresTerms) ? '-' : (isExpTheme ? `${listing.bathrooms} Ba` : `${listing.bathrooms} Bath`)}
            {!(isPrivate || requiresTerms) && listingToCompare &&
                <>
                  {getDifferenceLabel(differenceInBathrooms)}
                  <span className={styles[getArrowClassName(differenceInBathrooms)]} />
                </>
            }
          </p>
          <p className={styles['listing-detail']} data-testid={listingCardIds.listingSqft}>
            <SqftIcon className={styles.icon} />
            {(isPrivate || requiresTerms) ? '-' : <SqftText squareFootageLabel={listing.sqftLabel} render={formattedSqft => <>{formattedSqft}</>} />}
            {!(isPrivate || requiresTerms) && listingToCompare && <span className={styles[getArrowClassName(differenceInSqft)]} />}
          </p>
        </div>
      </div>
    );
  };

  return renderModalDialog();
}
