import React from 'react';
import { dasherize } from '@zoocasa/node-kit/strings/dasherize';
import styles from './style.module.scss';
import { listingPageModalIds } from 'constants/test-constants';

export interface ListingStatusData {
  statusLabel: string;
  statusId: number | null;
}
interface Props {
  listing: ListingStatusData;
  className?: string;
}

export default function ListingStatus({ listing, className }: Props) {
  const { statusLabel } = listing;
  const statusClass = dasherize(statusLabel);

  return (
    <div className={`${styles.component} ${styles[statusClass]} ${className}`}>
      <p data-testid={listingPageModalIds.listingStatus}>{statusLabel}</p>
    </div>
  );
}
