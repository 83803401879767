import React, { MouseEvent } from 'react';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
  isExpTheme?: boolean;
}

const BathIcon = ({ className, onClick, isExpTheme }: Props) => {
  const { themeName } = useThemeContext();
  const isExpThemeFromContext = themeName && themeName !== ThemeNames.ZOOCASA;

  return (
    <>
      { !(isExpTheme || isExpThemeFromContext) ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className={className} onClick={onClick}>
          <g transform="matrix(0.8000001 0 0 0.8000001 0 0)">
            <path transform="matrix(1 0 0 1 0 0)" d="M18.5976 8.921177L16.3296 8.921177L16.3296 2.625663C 16.3296 -0.1435266 12.5922 -1.018785 11.35682 1.451822L11.35682 1.451822L10.36139 3.442597C 10.1747 3.816023 10.32601 4.270114 10.69948 4.456846C 11.07298 4.64354 11.52703 4.492189 11.71377 4.118763L11.71377 4.118763L12.70919 2.127989C 13.23314 1.080059 14.8176 1.449781 14.8176 2.625663L14.8176 2.625663L14.8176 8.921177L0.7559999 8.921177C 0.3384612 8.921177 6.377121E-13 9.259639 6.377121E-13 9.677139L6.377121E-13 9.677139L6.377121E-13 12.66606C 6.377121E-13 15.15198 1.748212 17.23699 4.079754 17.75712L4.079754 17.75712L3.834658 18.27312C 3.655524 18.65025 3.816023 19.10117 4.193154 19.2803C 4.568735 19.45876 5.020558 19.30041 5.200372 18.92181L5.200372 18.92181L5.694229 17.8822L13.65941 17.8822L14.15326 18.92181C 14.33259 19.29939 14.78373 19.45925 15.16048 19.2803C 15.53765 19.10117 15.69815 18.65022 15.51898 18.27312L15.51898 18.27312L15.27388 17.75712C 17.60539 17.23695 19.3536 15.15198 19.3536 12.66606L19.3536 12.66606L19.3536 9.677139C 19.3536 9.259639 19.01514 8.921177 18.5976 8.921177zM17.8416 12.66606C 17.8416 14.70858 16.1798 16.37027 14.1372 16.37027L14.1372 16.37027L5.2164 16.37027C 3.173801 16.37027 1.512 14.70858 1.512 12.66606L1.512 12.66606L1.512 10.43314L17.8416 10.43314L17.8416 12.66606z" stroke="none" fill="#191B1E"/>
          </g>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="-2 -1 16 16"className={className} onClick={onClick}>
          <path d="M3.66671 5.00004C4.40309 5.00004 5.00004 4.40309 5.00004 3.66671C5.00004 2.93033 4.40309 2.33337 3.66671 2.33337C2.93033 2.33337 2.33337 2.93033 2.33337 3.66671C2.33337 4.40309 2.93033 5.00004 3.66671 5.00004Z" fill="#0C0F24"/>
          <path d="M12.3334 7.66671V2.22004C12.3334 1.18004 11.4867 0.333374 10.4467 0.333374C9.94671 0.333374 9.46671 0.533374 9.11337 0.886707L8.28004 1.72004C8.17337 1.68671 8.06004 1.66671 7.94004 1.66671C7.67337 1.66671 7.42671 1.74671 7.22004 1.88004L9.06004 3.72004C9.19337 3.51337 9.27337 3.26671 9.27337 3.00004C9.27337 2.88004 9.25337 2.77337 9.22671 2.66004L10.06 1.82671C10.16 1.72671 10.3 1.66671 10.4467 1.66671C10.7534 1.66671 11 1.91337 11 2.22004V7.66671H6.43337C6.23337 7.52671 6.05337 7.36671 5.88671 7.18671L4.95337 6.15337C4.82671 6.01337 4.66671 5.90004 4.49337 5.82004C4.28671 5.72004 4.06004 5.66671 3.82671 5.66671C3.00004 5.67337 2.33337 6.34004 2.33337 7.16671V7.66671H0.333374V11.6667C0.333374 12.4 0.933374 13 1.66671 13C1.66671 13.3667 1.96671 13.6667 2.33337 13.6667H11.6667C12.0334 13.6667 12.3334 13.3667 12.3334 13C13.0667 13 13.6667 12.4 13.6667 11.6667V7.66671H12.3334ZM12.3334 11.6667H1.66671V9.00004H12.3334V11.6667Z" fill="#0C0F24"/>
        </svg>
      )}
    </>
    
  );
};

export default BathIcon;
