import React from 'react';
import styles from './style.module.scss';
import { buildClassName } from 'utils/build-class-name';

interface Props {
  isSmallCard?: boolean;
  isImageReady: boolean;
}

export default function ListingCardNewImageOverlay({ isSmallCard, isImageReady }: Props) {
  return (
    <div className={buildClassName(styles.component, !isImageReady && styles.black)}>
      <p className={buildClassName(styles['new-overlay-text'], isSmallCard && styles['small-text'])}>
        Sign In Required
      </p>
    </div>
  );
}