import React, { useEffect, useRef, useState } from 'react';
import useWindowWidth from 'hooks/use-window-width';
import dateFromNow from 'utils/date-from-now';
import { buildClassName } from 'utils/build-class-name';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';
import { TryScrambleText } from 'utils/text-scrambler';
import styles from './style.module.scss';

import type { ListingStatusData } from 'components/listing-status';

export interface ListingTimestampData extends ListingStatusData {
  soldAt: Date | null;
  expiredAt: Date | null;
  addedAt: Date;
  updatedOn: Date | null;
}
interface Props {
  listing: ListingTimestampData;
  isSmallCard?: boolean;
  className?: string;
  isMinimal?: boolean;
  testId?: string;
  isVisible?: boolean;
  shouldScramble: boolean;
}

export default function ListingTimestamp({ listing, isSmallCard, isMinimal, className, testId, isVisible, shouldScramble }: Props) {
  const { soldAt, expiredAt, updatedOn, addedAt, statusId } = listing;
  const windowWidth = useWindowWidth();
  const timestampElement = useRef<HTMLDivElement>(null);
  const [isWrapping, setIsWrapping] = useState(false);
  const isExpired = statusId === 4 || expiredAt;
  const timestamp = soldAt || isExpired && (updatedOn || addedAt) || addedAt;
  const isFutureDate = new Date() < new Date(timestamp);
  const [dateLabel, setDateLabel] = useState<string>();
  const blurStyle = !isVisible ? 'blur' : '';
  const { themeName } = useThemeContext();
  const isExpTheme = themeName && themeName !== ThemeNames.ZOOCASA;

  useEffect(() => {
    if (dateLabel) {
      setIsWrapping(timestampElement.current!.offsetHeight > 15);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth]);

  useEffect(() => {
    if (!isFutureDate) {
      const smallDateLabel = (date: Date) => dateFromNow(date)?.replace('ago', '');

      if (soldAt) {
        setDateLabel(isSmallCard ? smallDateLabel(soldAt) : `${(isWrapping && styles['small-card']) || isMinimal ? '' : 'Sold '}${dateFromNow(soldAt)}`);
      } else if (isExpired) {
        setDateLabel(isSmallCard ? smallDateLabel(updatedOn || addedAt) : `${isWrapping || isMinimal && styles['small-card'] ? '' : 'Removed '}${dateFromNow(updatedOn || addedAt)}`);
      } else if (addedAt) {
        setDateLabel(isSmallCard ? smallDateLabel(addedAt) : `${(isWrapping && styles['small-card']) || isMinimal ? '' : 'Added '}${dateFromNow(addedAt)}`);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soldAt, isExpired, addedAt]);

  return <>{dateLabel && <p className={buildClassName(styles.component, className, blurStyle, isExpTheme && styles.exp)} data-testid={testId} ref={timestampElement}>{TryScrambleText(dateLabel, shouldScramble)}</p>}</>;
}
